import { css, keyframes } from '@emotion/react';

export const container = css`
  position: relative;

  padding: 200px 0 100px;
  margin-bottom: -1px;

  background: radial-gradient(116.65% 97.78% at 50% 97.84%, #000000 0%, #0852f6 48.96%, #01d9f7 100%),
    linear-gradient(0deg, #ffffff, #ffffff), #c4c4c4;

  @media all and (max-width: 1200px) {
    padding: 160px 0 80px;
  }

  @media all and (max-width: 640px) {
    padding: 120px 0 200px;
  }
`;

export const wrapper = css`
  position: relative;
  z-index: 3;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 108px;

  @media all and (max-width: 1200px) {
    padding: 0 10%;
  }

  @media all and (max-width: 640px) {
    padding: 0 6%;
  }
`;

export const illustWrapper = css`
  position: absolute;
  right: 0;
  bottom: -200px;

  width: 100%;

  overflow-x: hidden;
  user-select: none;
  pointer-events: none;
  z-index: 2;
  mix-blend-mode: luminosity;

  @media all and (max-width: 1024px) {
    bottom: -220px;
  }

  @media all and (max-width: 640px) {
    bottom: -150px;
  }
`;

export const illustContainer = css`
  position: relative;
  margin-left: 45%;
  width: 900px;
  height: 900px;
  overflow: hidden;

  @media all and (max-width: 1024px) {
    margin-left: 35%;
    width: 750px;
  }

  @media all and (max-width: 640px) {
    margin: 0 auto;
    width: 100%;
  }
`;

const ringAnimation = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

const pylonAnimation = keyframes`
  0% {
    margin-bottom: 0;
  }
  50% {
    margin-bottom: 60px;
  }
  100% {
    margin-bottom: 0;
  }
`;

export const thePylon = css``;

export const pylonIllust = css`
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 600px;
  transform: translateX(-50%);
  z-index: 1;

  animation: ${pylonAnimation} 8s ease-in-out infinite;

  @media all and (max-width: 1024px) {
    height: 400px;
  }

  @media all and (max-width: 640px) {
    height: 300px;
  }
`;

export const pylonVideo = css`
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 600px;
  transform: translateX(-50%);
  z-index: 1;

  animation: ${pylonAnimation} 8s ease-in-out infinite;

  @media all and (max-width: 1024px) {
    height: 400px;
  }

  @media all and (max-width: 640px) {
    height: 300px;
  }
`;

export const pylonEffect = css`
  animation-delay: 3s;
`;

export const ringIllust = css`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;

  animation: ${ringAnimation} 6s ease-in-out infinite;
`;

export const illust = css`
  width: 1500px;
  margin-right: -400px;
  pointer-events: none;

  @media all and (max-width: 1024px) {
    width: 1000px;
    margin-right: -300px;
  }

  @media all and (max-width: 640px) {
    width: 600px;
    margin: 0 auto;
    margin-left: calc((100vw - 600px) / 2);
  }
`;

export const titleContainer = css`
  margin-bottom: 48px;

  @media all and (max-width: 640px) {
    margin-bottom: 32px;
  }
`;

export const titleWrapper = css`
  margin-bottom: 48px;
`;

export const title = css`
  color: white;

  font-size: 128px;
  line-height: 108px;

  @media all and (max-width: 1200px) {
    margin-bottom: 32px;
    font-size: 100px;
    line-height: 90px;

    &:first-child {
      margin-bottom: 8px;
    }
  }

  @media all and (max-width: 1024px) {
    margin-bottom: 36px;
    font-size: 80px;
    line-height: 72px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 44px;
  }
`;

export const description = css`
  font-size: 20px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.9);

  @media all and (max-width: 640px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const inputWrapper = css`
  display: flex;
  align-items: center;

  width: 360px;

  color: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;

  background: rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(14px);
  border-radius: 4px;

  transition: background 0.25s ease;

  &:focus-within {
    background: rgba(255, 255, 255, 0.33);
  }

  @media all and (max-width: 640px) {
    display: block;
    width: 100%;
  }
`;

export const input = css`
  flex-grow: 1;
  height: 42px;
  padding: 0 16px;
  padding-right: 0;

  color: inherit;
  font: inherit;

  background: none;
  border: none;
  outline: none;

  &::placeholder {
    color: rgba(255, 255, 255, 0.66);
  }

  @media all and (max-width: 640px) {
    width: 100%;
    text-align: center;
    font-size: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
`;

export const submitButton = css`
  flex-shrink: 0;
  height: 42px;
  padding: 0 16px;

  color: inherit;
  font: inherit;

  @media all and (max-width: 640px) {
    width: 100%;
    font-size: 16px;
  }
`;

export const cta = css`
  display: inline-block;
  width: 220px;
  background: rgba(255, 255, 255, 0.2);
  padding: 16px 24px;
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
  font-weight: 500;
  border-radius: 6px;
  text-align: center;
  transition: all 150s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.33);
  }
`;

export const stats = css`
  position: relative;
  display: flex;
  flex-direction: row;
  color: #ffffff;
  top: 72px;

  @media all and (max-width: 640px) {
    flex-direction: column;
  }
`;

export const statsMetric = css`
  margin: 0 56px 16px 0;

  h3 {
    margin: 4px 0 0 0;
    font-weight: 500;
    color: #ffffff;
  }

  p {
    color: #ffffff;
  }

  @media all and (max-width: 640px) {
    margin: 0 0 16px 0;
  }
`;
